.faq-element {
  border-bottom: #f2f2f2 solid 1px;
  padding-bottom: 20px;
  padding-top: 15px;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  height: fit-content;
}

.faq-arrow {
  width: 50px;
  transform: rotate(-90deg);
  transition: all 0.2s;
  padding: 0 5px;
}

.faq-answer {
  text-align: left;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  max-height: 0px;
  overflow: hidden;
  transition: all 0.1s;
  padding-right: 50px;
}

.faq-element.faq-active .faq-answer {
  padding: 20px 50px 20px 0;
  max-height: none;
}

.faq-element.faq-active .faq-arrow {
  transform: rotate(-180deg);
}
