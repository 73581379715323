.article-block {
  padding-bottom: 15px;
  margin-bottom: 30px;
  border-bottom: #f2f2f2 solid 1px;
}
.article-content a {
  color: #ff6900;
}
.article-img {
  height: 10vh;
  border-radius: 8px;
  width: 100%;
  height: 218px;
}

.article-header {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  padding: 15px 0;
}

.article-readingtime {
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: #ff6900;
  padding-left: 8px;
  padding-right: 4px;
}

.article-readingtime-aux {
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
}
