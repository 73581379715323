.custom-radio {
  display: flex;
  align-content: center;
  align-items: center;
  position: relative;
  padding-top: 0.75rem /* 12px */;
  padding-bottom: 0.75rem /* 12px */;
  padding-left: 1rem /* 16px */;
  padding-right: 1rem /* 16px */;
  border-radius: 0.5rem /* 8px */;
  background: #f9f9f9;
  height: 62px;
}

.custom-radio span {
  font-weight: 600;
}

.custom-radio .checkmark-on {
  width: 2rem;
  height: 2rem;
  z-index: 20;
  position: absolute;
  margin-right: 1.25rem;
  display: none;
}

.custom-radio .checkmark-off {
  width: 2rem;
  height: 2rem;
  margin-right: 1.25rem;
  display: block;
}

.custom-radio input {
  display: none;
}

.custom-radio input:checked + .checkmark-on {
  display: block;
}
