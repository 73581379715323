.tracker-day-wrapper {
  position: relative;
  width: 46px;
  border-radius: 5px;
  display: grid;
  grid-template-rows: 1fr 1fr 4fr 1fr;
  padding-top: 10px;
}

.tracker-day-bar-wrapper {
  display: flex;
  flex-direction: column-reverse;
  flex: 1 0;
  align-items: center;
  top: 62px;
  left: -1px;
  width: 100%;
  height: 85px;
  padding-top: 10px;
  padding-bottom: 3px;
}

.tracker-day-bar-weekday {
  text-align: center;
  font-size: 10px;
  line-height: 12px;
  color: #aaaaaa;
  padding-bottom: 5px;
}

.tracker-day-bar-monthday {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #666666;
}
.tracker-day-bar-minutes {
  text-align: center;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #aaaaaa;
  min-height: 12px;
}

.tracker-day-bar {
  width: 5px;
  background: #17d85c;
  border-radius: 4px;
  transition: all ease 0.5s;
}

.tracker-day-bar.zero {
  height: 5px !important;
  width: 5px;
  background: #aaaaaa;
  border-radius: 4px;
  transition: all ease 0.5s;
}

.tracker-day-wrapper.day-active {
  border: #ff6900 solid 2px;
  border-radius: 5px;
}

.tracker-day-wrapper.day-active .tracker-day-bar {
  background: #ff6900;
}

.tracker-day-wrapper.day-active .tracker-day-bar-minutes {
  color: #303030;
}

.tracker-day-wrapper.day-active .tracker-day-bar-weekday,
.tracker-day-wrapper.day-active .tracker-day-bar-monthday {
  color: #ff6900;
}
