.pwa-modal-wrapper {
  position: fixed;
  bottom: 10px;
  z-index: 99;
  width: 100vw;
  height: 100vh;
}

.pwa-modal {
  position: absolute;
  bottom: 10px;
  z-index: 99;
  border-radius: 8px;
}

.close-pwa-modal {
  justify-content: flex-end;
  align-items: center;
  display: flex;
  gap: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.close-pwa-modal span {
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  position: relative;
  color: #ffffff;
}

.close-pwa-modal span:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #FFF;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.close-pwa-modal span:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.pwa-modal-top {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 150px;
  padding: 0px 20px;
  background-color: #ffffff;
  margin-bottom: -1px;
}

.pwa-modal-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  background-color: #f2f2f2;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.pwa-modal-bottom::after {
  content: '';
  height: 15px;
  width: 15px;
  position: absolute;
  background-color: #f2f2f2;
  bottom: -7.5px;
  transform: rotate(45deg);
}

.pwa-modal-bottom span {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #303030;
}

.pwa-modal p {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #ff6900;
}
