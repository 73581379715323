.launch {
  background: rgb(255, 105, 0, 1);
  background: linear-gradient(0deg, rgba(255, 105, 0, 1) 0%, rgba(255, 105, 0, 1) 33%, rgba(255, 255, 255, 0) 100%);

  overflow-x: hidden;
  position: fixed;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.launch-img {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: #ff6900;
  z-index: 1;
  mix-blend-mode: normal;
  object-fit: cover;
}

.launch-img img {
  object-fit: contain;
  width: 100% !important;
}

.launch-content {
  z-index: 10;
}

.launch-welcome {
  color: white;
  text-align: center;
  font-weight: 600;
}

.brand-active {
  top: 5%;
}

.launch-backdrop {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(255, 105, 0, 0) 0%, #ff6900 80%, #ff6900 100%);
  z-index: 2;
}

.login-backdrop.login-show {
  top: 0;
}

.login-backdrop.login-blur {
  backdrop-filter: blur(4px);
}

.login-form {
  position: absolute;
  width: 100%;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  background-color: white;
  z-index: 20;
  padding-top: 1.25rem;
  padding-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0;
}

.login-screen {
  position: fixed;
  width: 100%;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  background-color: white;
  z-index: 20;
  padding-top: 1.25rem;
  padding-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 500ms;
}

.login-title {
  font-weight: 700;
  text-align: left;
  font-size: 1.875rem;
  line-height: 2.25rem;
}
