.checkbox-equipment {
    height: 28px;
    width: 28px;
    box-shadow: 0 7px 64px rgb(0 0 0 / 7%);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-bottom: 0px !important;
}

.equipment-wrapper {
    margin: 10px 0;
}

.equipment-wrapper div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
