.loading-screen {
  position: fixed;
  z-index: 99;
  background: linear-gradient(180deg, #ff8a28 0%, #ff710e 100%);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading-decoration {
  position: absolute;
  animation-name: rotate;
  animation-duration: 10s;
}

.loading-text {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;
  color: white;
}

.dot {
  position: absolute;
  top: 48%;
  height: 5px;
  width: 5px;
  background-color: white;
  border-radius: 100%;
  animation: bounce 0.5s infinite;
}

.dot1 {
  left: 46.5%;
}

.dot2 {
  animation-delay: 0.1s;
  left: 49.2%;
}

.dot3 {
  animation-delay: 0.2s;
  left: 52%;
}
