.navbar-container {
  position: fixed;
  bottom: 0px;
  background-color: transparent;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  z-index: 10;
}

.navbar {
  border-radius: 0.5rem;
  display: flex;
  background: linear-gradient(180deg, #ff8a28 0%, #ff710e 100%);
  box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  min-width: 88vw;
}

.navbar.alt {
  background: linear-gradient(225deg, #17d85c 0%, #0c9e40 100%);
}

.nav-trainer.alt {
  background: linear-gradient(180deg, #ff8a28 0%, #ff710e 100%);
}

.nav-trainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  border-radius: 9999px;
  justify-content: space-evenly;
  padding-top: 0.75rem /* 12px */;
  padding-bottom: 0.75rem /* 12px */;

  background: linear-gradient(225deg, #17d85c 0%, #0c9e40 100%);

  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2), 0px 13.5662px 30.1471px rgba(0, 0, 0, 0.08),
    0px 5.6347px 12.5216px rgba(0, 0, 0, 0.104254), inset 0px 5px 15px rgba(255, 255, 255, 0.4);

  height: 10.5vh;
  width: 10.5vh;
}

.nav-spacer {
  width: 28%;
}

.nav-icon {
  height: 18px;
  width: auto;
}

.nav-element {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 0.5rem /* 8px */;
  margin-bottom: 0.5rem /* 8px */;
  height: 6vh;
  width: 18%;
  color: white;
  opacity: 0.6;
}

.nav-element.selected {
  opacity: 1;
}

.nav-element-text {
  font-weight: 600;
  font-size: 10px;
  color: inherit;
}
