.faq-aux {
  font-size: 13px;
  line-height: 18px;
  color: #666666;
}

.faq-email-aux {
  font-size: 14px;
  line-height: 22px;

  color: #aaaaaa;
}

.faq-alert-ok {
  height: 60px;
  width: 100%;
  background: #dcf9e7;
  border-radius: 8px;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #17d85c;
  justify-content: center;
}

.faq-alert-error {
  height: 60px;
  width: 100%;
  background: #f9dcdd;
  border-radius: 8px;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #d81737;
  justify-content: center;
}
