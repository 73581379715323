.related-selector {
  overflow: auto;
  white-space: nowrap;
}

.related-selector::-webkit-scrollbar {
  display: none;
}

.related-element {
  position: relative;
  width: 241px;
  height: 236px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.1) 29.17%,
    rgba(0, 0, 0, 0.4) 67.19%,
    rgba(0, 0, 0, 0.8) 100%
  );
  border-radius: 7px;
  margin: 7.5px;
}

.related-title {
  padding: 0 5px;
  font-weight: bold;
  font-size: 14px;
  color: #ff6900;
  max-width: 241px;
  max-height: 5000px;
  text-align: left;
  white-space: normal;
}

.related-first-item {
  margin-left: 7.5vw;
}

.related-last-item {
  margin-right: 7.5vw;
}
