.pagination {
    display: flex;
    gap: 10px;
    margin-top: 40px;
    width: 100%;
    justify-content: space-around;
}

.pagination-item{
    padding: 15px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    color: #ff6900;
    font-weight: 700;
    background-color: white;
    border: #ff6900 solid 1px;
}

.pagination-item-active{

    padding: 15px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    color: white;
    font-weight: 700;
    background-color: #ff6900;

}