.label-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
  min-width: 40%;
}

.input-right {
  flex: 1 1 200%;
}
