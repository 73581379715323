.objective-warning {
  display: flex;
  flex-direction: column;
  padding-left: 1.25rem /* 20px */;
  padding-right: 1.25rem /* 20px */;
  padding-bottom: 1.25rem /* 20px */;
  padding-top: 0.75rem /* 12px */;
  width: 85vw;
  border-radius: 0.5rem /* 8px */;
  background-color: #e6f5fe;
  color: #12bef6;
}
