.week-calendar-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #666666;
}

.week-calendar-titlebar {
  display: flex;
  padding: 0 1rem 0 0.5rem;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
}

.week-calendar-time {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #17d85c;
}

.week-calendar {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  background-color: white;
  margin: 0.75rem 0;
  padding: 10px 0rem;
}

.week-calendar-wrapper {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 0.75rem 0;
  padding: 10px 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
}
