.profile-option {
  border-width: 1px;
  border-color: rgba(243, 244, 246, 1);
  border-radius: 0.5rem;
  display: flex;
  width: 85vw;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}

.profile-option.sec {
  background-color: rgba(243, 244, 246, 1);
}
