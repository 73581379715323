.popup {
  width: 100%;
  border-top-left-radius: 1.5rem /* 24px */;
  border-top-right-radius: 1.5rem /* 24px */;
  background-color: white;
  z-index: 20;
  padding-top: 1.25rem /* 20px */;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  max-height: 100%;
}

.popup .register {
  margin-left: 10px;
  text-align: left;
}

.popup-content {
  width: 100%;
  max-height: 65vh;
  overflow: scroll;
}
