.new-routine-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #303030;
}

.new-routine-aux-wrapper {
  padding-top: 20px;
  padding-bottom: 30px;
}

.new-routine-aux1 {
  color: #17d85c;
  font-weight: bold;
}

.new-routine-aux2 {
  color: #aaa;
}

.new-routine-aux3 {
  font-size: 18px;
  font-weight: bold;
}
