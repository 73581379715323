.show-pass-wrapper {
  position: absolute;
  z-index: 10;
  top: 50%;
  right: 5%;
  margin-top: -11px;
  width: 22px;
  height: 22px;
}

.show-pass {
  filter: invert(49%) sepia(39%) saturate(4634%) hue-rotate(359deg) brightness(99%) contrast(109%);
}

.input-field {
  width: 100%;
  height: 60px;
  padding: 22px 10px 0 14px;
  border-width: 1px;
  border-color: rgba(209, 213, 219, 1);
  border-radius: 0.5rem;
  background-color: transparent;
}

.text-area {
  width: 100%;
  padding: 22px 10px 0 14px;
  border-width: 1px;
  border-color: rgba(209, 213, 219, 1);
  border-radius: 0.5rem;
  background-color: transparent;
}

.input-field.noypad {
  padding-top: 0;
  padding-bottom: 0;
}

.input-label {
  position: absolute;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
  line-height: 0;
  transform-origin: top left;
  transition: all 0.15s ease-out;
  pointer-events: none;
  margin-bottom: -12px;
  top: 50%;
  left: 14px;
}

#input:focus-within .input-label,
.active {
  top: 30%;
  transform: scale(0.75);
}

.input-field.invalid-input {
  border-color: rgba(239, 68, 68, 1);
}

.invalid-label {
  color: rgba(239, 68, 68, 1);
}

.big-input-field {
  max-width: 100%;
  height: 100px;
  border-width: 1px;
  border-radius: 0.5rem;
  background-color: transparent;
  text-align: center;
  font-size: 1.875rem /* 30px */;
  line-height: 2.25rem /* 36px */;
  font-weight: 700;
  padding-right: 2rem /* 32px */;
}

.big-input-label {
  position: absolute;
  color: rgba(156, 163, 175, 1);
  pointer-events: none;
  top: 53%;
  right: 34%;
  line-height: 0px;
}

.sm-input {
  position: absolute;
  background-color: rgba(229, 231, 235, 1);
  border-radius: 0.25rem;
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
  color: rgba(107, 114, 128, 1);
  height: 40px;
  margin-top: -20px;
  width: 30vw;
  padding: 0 12px;
  top: 50%;
  right: 5%;
}

.sm-input-handle {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid #303030;
  position: absolute;
  top: calc(50% - 5px);
  right: 8%;
  z-index: 100;


}
