.panel-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.panel-hello {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #ff6900;
}

.panel-objective {
  background: #f9f9f9;
  border-radius: 3px;
  height: 37px;
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.panel-objective-title {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #303030;
}

.panel-objective-title2 {
  margin-left: 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #ff6900;
}

.panel-section-title {
  text-align: left;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #303030;
}

.panel-section-title {
  text-align: left;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #303030;
  position: relative;
  height: auto;
  padding-bottom: 12px;
}

.panel-routine-title {
  text-align: left;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #666666;
  position: relative;
  height: auto;
}

.panel-section-title.panel-section-active {
  color: #ff6900;
}

.panel-section-title.panel-section-active::before {
  content: '';
  bottom: 0;
  left: 0;
  position: absolute;
  width: 33px;
  border: #ff6900 2px solid;
  background-color: #ff6900;
  border-radius: 100px;
}

.panel-dashboard {
  border-radius: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 34px;
  padding-bottom: 30px;
  background-color: #f9f9f9;
}

.panel-link-routine {
  font-weight: 600;
  font-size: 10px;
  line-height: 22px;
  text-align: right;
  color: #ff6900;
}
