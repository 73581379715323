.trainer-overlay {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.6) 48.96%, rgba(0, 0, 0, 0.8) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  pointer-events: none;
}

.trainer-overlay2 {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 54.17%);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 310px;
  z-index: 11;
  pointer-events: none;
}

.exercises-wrapper {
  height: 60vh;
  overflow-x: scroll;
  padding-bottom: 100px;
}

.trainer-timer {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 15;
  color: white;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  width: 100%;
  padding-bottom: 10px;
  padding-top: 10px;
}

.timer-button {
  color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 15;
}

.trainer-button {
  z-index: 15;
}

.trainer-button > button {
  margin: 0;
}

.trainer-bar {
  display: flex;
  justify-content: space-between;
  z-index: 10;
  align-items: center;
}

.trainer-bar button {
  max-width: 20px;
}

.trainer-finish-screen {
  position: fixed;
  background: linear-gradient(180deg, #ff8a28 0%, #ff710e 100%);
  height: 100%;
  width: 100%;
  z-index: 80;
}

.trainer-drawer {
  z-index: 99 !important;
}

.resume-loading {
  height: 51px;
  z-index: 15;
}

.resume-loading .loader {
  width: 3em;
  height: 3em;
}

.resume-loading .loader:after {
  background: #000;
}

.mobile-fix {
  /* height: 100vh; */
  max-height: calc(var(--vh, 1vh) * 100);
}
