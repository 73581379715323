.trainer-counter {
  position: fixed;
  z-index: 99;
  background: linear-gradient(180deg, #ff8a28 0%, #ff710e 100%);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.trainer-counter-decoration {
  height: 120%;
  position: absolute;
  animation-name: rotate;
  animation-duration: 10s;
}

.trainer-halt-button {
  position: absolute;
  bottom: 10%;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  padding: 40px;
  color: #ffffff;
  opacity: 0.5;
}

.trainer-counter-number {
  font-weight: bold;
  font-size: 100px;
  line-height: 121px;
  text-align: center;
  color: #ffffff;
}

.trainer-counter-text {
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}
