.med-textarea {
  width: 100%;
  padding: 0.75rem;
  background-color: rgba(243, 244, 246, 1);
  margin-top: 0.75rem;
  height: 48px;
  border-radius: 0.5rem;
}

.form-med-label {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 0.5rem;
}
