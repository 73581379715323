button {
  width: 100%;
  height: 55px;
  font-weight: 600;
}

.main-button {
  border-radius: 0.5rem;
  color: white;
  background-color: #ff6900;
}

.acc-button {
  border-radius: 0.5rem;
  color: black;
  background-color: white;
}

.blue-button {
  font-size: 14px;
  line-height: 17px;
  height: 40px;
  color: white;
  background: #12bef6;
  border-radius: 8px;
  margin-top: 10px;
}

.green-button {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  height: 56px;
  color: white;
  background: linear-gradient(225deg, #17d85c 0%, #0c9e40 100%);
  border-radius: 8px;
}

.disabled-button {
  border-radius: 0.5rem;
  background-color: rgba(243, 244, 246, 1);
  color: rgba(209, 213, 219, 1);
}

.sec-button {
  background-color: transparent;
  border-width: 2px;
  border-color: white;
  color: white;
  border-radius: 0.5rem;
}

.ter-button {
  background-color: transparent;
  border-width: 2px;
  border-color: #ff6900;
  color: #ff6900;
  border-radius: 0.5rem;
}
