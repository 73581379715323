.modal {
  background-color: white;
  width: 85%;
  height: 95%;
  border-radius: 16px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  position: relative;
}

.modal-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  padding: 17px 0;
}

.modal-close {
  position: absolute;
  top: 20px;
  right: 30px;
}

.modal-content {
  overflow-y: scroll;
  height: 90%;
  padding: 0 24px;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
}
