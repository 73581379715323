.profile-bar-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  justify-content: center;
  height: 56px;
  top: 0;
}
.profile-bar {
  display: flex;
  align-items: center;
  width: 88vw;
}
