.articles-container {
  display: grid;
  gap: 40px;
  padding-bottom: 150px;
  align-items: center;
  justify-content: center;
}

.article-preview-img {
  height: 10vh;
  border-radius: 8px;
  width: 100%;
  height: 170px;
}

.article-preview-header {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #ff6900;
  padding-top: 0.75rem;
}

.article-preview-kicker {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
  padding-top: 0.75rem;
}
