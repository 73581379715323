.selector-button {
  height: 40px;
  border-width: 2px;
  border-color: #ff6900;
  border-radius: 8px;
  width: 100%;
  color: #ff6900;
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
}

.selector-button.selected {
  color: white;
  background-color: #ff6900;
}
