.workoutbar-wrapper {
  position: relative;
  height: 35px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.workoutbar {
  z-index: 1;
  flex-shrink: 0;
  flex-grow: 0;
  height: 100%;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all ease 0.2s;
}

.workout-progress-box {
  width: 43px;
  height: 23px;
  background: #17d85c;
  border-radius: 5px;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.workout-progress-bar {
  width: 100%;
  height: 7px;
  background: #17d85c;
  border-radius: 100px;
}

.workout-elements-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.workout-progress-bar-fill {
  width: 100%;
  height: 7px;
  background: white;
  border-radius: 100px;
  position: absolute;
  bottom: 0;
}

.workout-progress-text1 {
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #17d85c;
}

.workout-progress-text2 {
  font-size: 12px;
  line-height: 15px;
}
