.gender-selector {
  z-index: 99;
  height: 100px;
  width: 40vw;
  border-width: 1px;
  border-radius: 0.5rem;
  background-color: transparent;
}

.gender-icon {
  z-index: -1;
  top: 50%;
  width: 100%;
  margin-bottom: 0.5rem;
}

.gender-selector.selected {
  border-color: #ff6900;
  border-width: 2px;
}

.gender-selector h1 {
  z-index: -1;
  color: rgba(156, 163, 175, 1);
}

.gender-selector.selected h1 {
  color: #ff6900;
}
