.bar-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}
.bar {
  display: flex;
  align-items: center;
  padding-top: 1rem /* 16px */;
  padding-bottom: 1rem /* 16px */;
  width: 88vw;
}

.back-button {
  width: 2rem;
  max-height: 2rem;
}

.progress-bar {
  display: flex;
  justify-content: space-evenly;
  width: 11rem;
  padding-right: 1.25rem;
}
