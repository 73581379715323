.routine-list-day {
  border: 1px solid;
  border-radius: 100%;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  flex-shrink: 0;
  color: #666666;
  border-color: #dddddd;
}

.routine-list-day.today {
  color: #ff6900;
  border-color: #ff6900;
}

.routine-list-date {
  font-size: 12px;
  line-height: 15px;
  flex-shrink: 0;
  margin-left: 10px;
  font-size: 12px;
  line-height: 15px;
  color: #666666;
}

.routine-list-date.today {
  color: #ff6900;
  font-weight: bold;
}

.routine-list-button {
  width: 110px;
  height: 32px;
  border: 1px solid #ff6900;
  border-radius: 100px;
  color: #ff6900;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
}

.routine-list-button.today {
  background: #17d85c;
  border: none;
  color: white;
}

.rotine-container {
  max-height: calc(100vh - 22rem);
  margin-top: 1rem;
  overflow: scroll;
}


.rotine-container .ath-container:last-of-type {
  margin-bottom: 100px;
}