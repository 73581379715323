.filter-bar-wrapper {
  background-color: #f9f9f9;
  width: 100%;
  display: flex;
  justify-content: center;
}

.filter-bar {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 18px;
  height: 70px;
  align-items: center;
}
