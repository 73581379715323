.gif-modal {
  height: 30px;
}

.gif-modal img {
  display: none;
  height: 150px;
  width: auto;
}

.gif-modal-open .gif-modal-content { position: relative }

.gif-modal .close { display: none; }

.gif-modal-open .close {
  display: block;
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 42px;
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  transform: rotate(45deg);
}

.gif-modal-open {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
}

.gif-modal-open img {
  display: block;
  height: auto;
  width: 85vw;
}
