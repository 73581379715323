.video-preview-img {
  position: relative;
  width: 100%;
  height: 300px;
  border-radius: 7px;
  overflow: hidden;
}

.img {
  object-fit: cover;
  height: 300px;
  width: 100%;
}

.video-preview-overlay {
  position: absolute;
  width: 100%;
  height: 300px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.1) 29.17%,
    rgba(0, 0, 0, 0.4) 67.19%,
    rgba(0, 0, 0, 0.8) 100%
  );
  border-radius: 7px;
}

.video-header {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}

.video-player {
  border-radius: 7px;
  overflow: hidden;
}

.video-play-icon {
  position: absolute;
  height: 72px;
  width: 72px;
  top: 50%;
  margin-top: -36px;
  left: 50%;
  margin-left: -36px;
  z-index: 1;
}
