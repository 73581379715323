.profile-banner {
  width: 100%;
  height: 120px;
  background: linear-gradient(180deg, #ff8a28 0%, #ff710e 100%);
  position: relative;
}

.profile-banner-icon {
  position: absolute;
  z-index: 10;
  height: 40px;
  top: 50%;
  margin-top: -20px;
  left: 50%;
  margin-left: -165px;
}

.profile-banner img {
  psotion: absolute;
  z-index: 0;
  mix-blend-mode: screen;
}
