.dropdown-wrapper {
  position: relative;
}

.dropdown-input {
  background-color: rgba(243, 244, 246, 1);
  border-radius: 0.5rem;
  height: 48px;
  width: 100%;
  padding: 0px 10px 0 14px;
  appearance: none;
}

.dropdown-input.sec {
  border-width: 1px;
  border-color: rgba(209, 213, 219, 1);
  background-color: white;
  height: 60px;
}

.dropdown-input.ter {
  border-width: 1px;
  border: 1px solid #f2f2f2;
  background-color: white;
  height: 40px;
}
.base-value {
  color: #aaaaaa;
}

.dropdown-input-icon {
  pointer-events: none;
  position: absolute;
  top: 0px;
  right: 0px;
  display: block;
  height: 100%;
  width: 3rem;
  padding: 1rem;
}
