.screen-full-height {
  width: 85vw;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
}

.policy-link {
  display: inline-block;
  height: 0.75rem;
  padding: 0 0.5rem;
  color: rgba(156, 163, 175, 1);
  width: auto;
  font-weight: 400;
  text-decoration: underline;
}

.policy-spacer {
  display: inline-block;
  height: 0.75rem;
  color: rgba(156, 163, 175, 1);
  width: auto;
  font-weight: 400;
}
