.exercise-view-title {
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #303030;
}

.exercise-view-bar {
  background: #f9f9f9;
  border-radius: 15px;
  padding: 30px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 30px;
}

.exercises-view {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
  margin-top: 20px;
}

.botao-gif {
  border: 1px solid #ff6900;
  box-sizing: border-box;
  border-radius: 100px;
  width: 128px;
  height: 28px;
  gap: 7px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  background-color: #f9f9f9;
  display: flex;
  padding: 0 15px;
  align-items: center;

  /* Secondary Color/Grey 1 */

  color: #666666;
}

