.calendar-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
}

.calendar-month-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #303030;
  text-align: center;
  width: 100%;
}

.calendar-time {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #17d85c;
}

.calendar-time-aux {
  font-size: 12px;
  line-height: 15px;
  color: #aaaaaa;
}

.calendar-week {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  background-color: white;
  margin: 0.75rem 0;
  padding: 10px 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
}

.calendar-button {
  height: 32px;
  width: 32px;
  border: 1px solid #ff6900;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.calendar-button.disabled {
  border: 1px solid #aaa;
}

.calendar-next-arrow {
  width: 100%;
  transform: rotate(-90deg);
}

.calendar-prev-arrow {
  width: 100%;
  transform: rotate(90deg);
}
