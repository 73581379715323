.finish-screen-title {
  padding-top: 30px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.finish-screen-timer {
  font-weight: bold;
  font-size: 50px;
  line-height: 61px;
  text-align: center;
  letter-spacing: -1.5px;
  color: #ffffff;
  margin-bottom: 10px;
}

.finish-screen-aux {
  font-weight: light;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffede0;
  width: 60%;
}

.finish-screen-button {
  width: 130px;
  height: 32px;
  background: #ffffff;
  border-radius: 100px;
  margin-bottom: 50px;
  color: #ff6900;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  z-index: 10;
  flex-shrink: 0;
}
