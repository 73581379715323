
.pwa-modal-top-android {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 130px;
  padding: 0px 20px;
  background-color: #ffffff;
  margin-bottom: -1px;
}

.pwa-modal-bottom-android {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  background-color: #f2f2f2;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 0px 20px;
}

@media (max-height: 600px) {
  .pwa-modal-top-android { height: 85px; }
  .pwa-modal-bottom-android { height: 80px; }
}
