@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');

iframe::-webkit-scrollbar {
  display: none;
  width: 0 !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

* {
  font-family: Inter, sans-serif !important;
  @apply text-black;
}

input:focus-visible,
select:focus-visible {
  outline-offset: 0px;
  background-color: #fff;
  border-color: rgba(255, 105, 0, 0.25);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 105, 0, 0.25);
}

input,
select {
  transition: box-shadow ease 0.25s, border-color ease 0.25s;
  font-size: 14px;
  -webkit-appearance: none;
}

.hide-view {
  bottom: -999px;
}

.main-title {
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  text-align: left;
  margin-top: 8px;
  margin-bottom: 4px;
  color: #303030;
}

.sec-title {
  color: #aaaaaa;
}

.grid-inputs {
  @apply my-6 grid grid-cols-1 gap-3;
}

.ath-container {
  @apply flex flex-col items-center;
}

.w-product {
  width: 85vw;
}

.policies * {
  font-family: Inter !important ;
  font-style: normal !important ;
  font-weight: normal !important ;
  font-size: 13px !important ;
  line-height: 18px !important ;
  color: #666666 !important ;
  word-wrap: break-word;
  width: '100%' !important;
}

.policies p {
  font-family: Inter !important ;
  font-style: normal !important ;
  font-weight: normal !important ;
  font-size: 13px !important ;
  line-height: 18px !important ;
  color: #666666 !important ;
}


.policies strong {
  font-family: Inter !important ;
  font-style: normal !important ;
  font-weight: bold !important ;
  font-size: 14px !important ;
  line-height: 17px !important ;
  color: #ff6900 !important ;
}

.policies table {
  width: 100%;
}

.pass-green {
  --tw-text-opacity: 1;
  color: #009933;
}
