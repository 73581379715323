.category-selector-wrapper {
  background: #f9f9f9;
  border-radius: 15px;
  padding: 30px;
  margin: 40px;
  width: 100%;
}

.category-selector {
  overflow: auto;
  white-space: nowrap;
}

.category-selector::-webkit-scrollbar {
  display: none;
}

.category-element {
  display: inline-block;
  position: relative;
  width: 142px;
  height: 236px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.1) 29.17%,
    rgba(0, 0, 0, 0.4) 67.19%,
    rgba(0, 0, 0, 0.8) 100%
  );
  border-radius: 7px;
  margin: 5px;
}

.category-overlay {
  position: absolute;
  width: 142px;
  height: 236px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.1) 29.17%,
    rgba(0, 0, 0, 0.4) 67.19%,
    rgba(0, 0, 0, 0.8) 100%
  );
  border-radius: 7px;
}

.category-title {
  position: absolute;
  font-weight: bold;
  font-size: 14px;
  color: white;
  bottom: 10%;
  width: 100%;
  text-align: center;
}

.category-first-item {
  margin-left: 7.5vw;
}

.category-last-item {
  margin-right: 7.5vw;
}
