.exercise-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: white;
}

.exercise-title.exercise-complete {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-decoration-line: line-through;
  color: #17d85c;
}

.exercise-checkbox {
  height: 28px;
  width: 28px;
  box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.exercise-text-wrapper {
  border-bottom: solid #dddddd42 1px;
  width: 100%;
  padding-bottom: 16px;
}

.exercise-details-title {
  font-size: 12px;
  line-height: 15px;
  color: #aaaaaa;
}

.exercise-details-content {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: white;
}

.exercise-text-wrapper.alt .exercise-title {
  color: #303030;
}

.exercise-text-wrapper.alt .exercise-details-content {
  color: #303030;
}
